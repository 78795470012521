export interface BoundingBox {
  xmax: number;
  xmin: number;
  ymax: number;
  ymin: number;
}

export interface DateRange {
  begin: string | null;
  end: string | null;
}

export interface Filters {
  term: string | null;
  strict: boolean;
  dates: DateRange | null;
  nights: number | null;
  people: number | null;
  petsAllowed: boolean;
  firesAllowed: boolean;
  accessible: boolean;
  excludeFCFS: boolean;
  excludeGroup: boolean;
  excludeUnreleased: boolean;
  onlyMatches: boolean;
  bounds: BoundingBox | null; // Can be null until map's bounding box is calculated
  zoom: number | null;
  version: number;
}

export const DEFAULT_FILTERS: Readonly<Filters> = Object.freeze({
  term: null,
  strict: false,
  dates: null,
  nights: null,
  people: null,
  petsAllowed: false,
  firesAllowed: false,
  accessible: false,
  excludeFCFS: false,
  excludeGroup: false,
  excludeUnreleased: false,
  onlyMatches: false,
  bounds: null,
  zoom: null,
  version: 0,
});

export function filtersToQueryVariables(filters: Filters): any {
  return {
    term: filters.term,
    xmax: filters.bounds?.xmax,
    xmin: filters.bounds?.xmin,
    ymax: filters.bounds?.ymax,
    ymin: filters.bounds?.ymin,
    zoom: filters.zoom,
    beginDate: filters.dates?.begin,
    endDate: filters.dates?.end,
    nights: filters.nights,
    people: filters.people,
    strict: filters.strict,
    firesAllowed: filters.firesAllowed,
    petsAllowed: filters.petsAllowed,
    accessible: filters.accessible,
    excludeFCFS: filters.excludeFCFS,
    excludeGroup: filters.excludeGroup,
    excludeUnreleased: filters.excludeUnreleased,
    onlyMatches: filters.onlyMatches,
    page: 1,
    pageSize: 500,
    facetSize: 100,
  };
}
