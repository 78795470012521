import {ImageProps} from "next/image";

import {Media} from "../graphql/types";

export interface Props {
  media: Partial<Media>;
  imageProps?: Partial<ImageProps>;
}

export default function MediaImage({media, imageProps = {}}: Props) {
  return (
    <img
      {...imageProps}
      key={media.id}
      className="media-image"
      src={media.url || ""}
      title={media.title || ""}
      alt={media.title || ""}
      loading="lazy"
    />
  );
}
