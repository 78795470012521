import {track} from "@amplitude/analytics-browser";
import type {NextPage} from "next";
import Head from "next/head";
import React, {useEffect} from "react";

import Search from "../components/Search";
import {PAGE_DESCRIPTION} from "../constants";

const HomePage: NextPage & {whyDidYouRender?: boolean} = () => {
  useEffect(() => {
    track("Search Page Viewed");
  }, []);

  return (
    <>
      <Head>
        <meta name="description" content={PAGE_DESCRIPTION} />

        <meta property="og:title" content="Camp Observer" />
        <meta property="og:description" content={PAGE_DESCRIPTION} />
        <meta property="og:site_name" content="Camp Observer" />
      </Head>

      <main>
        <Search />
      </main>
    </>
  );
};

// HomePage.whyDidYouRender = true;

export default HomePage;
