import {track} from "@amplitude/analytics-browser";
import {useQuery, useLazyQuery} from "@apollo/client";
import {
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faLocationDot,
  faMap,
  faCheck,
  faFilter,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Alert, Button, Chip, Drawer, Group, Menu, Text} from "@mantine/core";
import {debounce, isEqual} from "lodash";
import {Coordinate} from "ol/coordinate";
import React, {useState, useEffect, useRef, useMemo} from "react";
import NoSSR from "react-no-ssr";
import {v5 as uuidNameSpace, v4 as getNS} from "uuid";

import FilterPanel from "./FilterPanel";
import {Filters, DEFAULT_FILTERS} from "./Filters";
import ResultsStrip from "./ResultsStrip";
import classes from "./Search.module.scss";
import SearchLoader from "./SearchLoader";
import * as queries from "../../graphql/queries";
import type {
  Unit,
  Location,
  Park,
  Facility,
  SearchResult,
  MapCluster,
} from "../../graphql/types";
import {getLogger} from "../../logging";
import {useLocalStorage} from "../../utils";
import {usePosition} from "../contexts/PositionContext";
import MapView from "../MapView";
import type {SelectionEvent, HoverEvent} from "../MapView";
import PlacePicker from "../PlacePicker";
import FacilityPopover from "../popovers/FacilityPopover";
import ParkPopover from "../popovers/ParkPopover";
import {SearchPopoverProps} from "../popovers/types";
import UnitPopover from "../popovers/UnitPopover";

const log = getLogger(__filename);
const RequestNameSpace = getNS();

// Search preferences that will be saved in localStorage
// Changes to this must be backwards compatible or it will break the page for users
export interface SearchPrefs {
  filters?: Filters;
  center?: Coordinate | null; // Deprecated
  center2?: Location | null;
  zoom?: number | null;
  showFilters: boolean;
  satelliteView?: boolean; // Deprecated
  showResultsStrip: boolean;
}

export const DEFAULT_SEARCH_PREFS: SearchPrefs = {
  showFilters: false,
  showResultsStrip: true,
};

export interface Props {}

export default function Search(props: Props) {
  // const {authentication} = useAuth();
  // const settings: UserSettings = getUserSettings(authentication.user);
  const {data: {user: {location: locationResult = {}} = {}} = {}} = useQuery(
    queries.GET_LOCATION
  );
  const [
    executeSearch,
    {
      loading: searchLoading,
      error: searchError,
      data: {search: {search: searchResult = {}} = {}} = {},
    },
  ] = useLazyQuery(queries.MAP_SEARCH);
  const [results, setResults] = useState<SearchResult[]>([]);
  const [searchPrefs, setSearchPrefs] = useLocalStorage<SearchPrefs>(
    "search",
    DEFAULT_SEARCH_PREFS
  );
  const searchPrefsRef = useRef(searchPrefs);
  const [showFilters, setShowFilters] = useState<boolean>(
    searchPrefs?.showFilters || false
  );
  const filters = searchPrefs?.filters || DEFAULT_FILTERS;
  const [selection, setSelection] = useState<SelectionEvent | null>(null);
  const [hover, setHover] = useState<HoverEvent | null>(null);
  const [mapRect, setMapRect] = useState<DOMRect | null>(null);
  const position = usePosition();
  const [mapCenter, setMapCenter] = useState<Location | null>(null);
  const [ownCenter, setOwnCenter] = useState<Location | null>(null);
  const [resultsStripOpen, setResultsStripOpen] = useState<boolean>(
    true // searchPrefs?.showResultsStrip
  );

  // Initially center the map
  useEffect(() => {
    if (mapCenter) {
      return;
    }

    let userLocation: Location | null;
    if (searchPrefs?.center2) {
      // Get location from previous search preferences
      log.info("Got user location from previous search prefs");
      userLocation = searchPrefs.center2;
      setMapCenter(userLocation);
    } else if (position.coordinates) {
      // Get location from user's detected geolocation
      log.info("Got user location from geolocation API");
      userLocation = {
        latitude: position.coordinates.latitude,
        longitude: position.coordinates.longitude,
      };
      setOwnCenter(userLocation);
    } else if (locationResult) {
      // Get location from user's IP address
      log.info("Got user location from IP address");
      userLocation = locationResult;
      setOwnCenter(userLocation);
    }
  }, [mapCenter, searchPrefs, locationResult]);

  // Re-center the map when the user's tracked position changes
  useEffect(() => {
    if (position.updating || !position.coordinates) {
      return;
    }

    setOwnCenter({
      latitude: position.coordinates.latitude,
      longitude: position.coordinates.longitude,
    });
  }, [position.coordinates, position.updating]);

  useEffect(() => {
    if (ownCenter) {
      setMapCenter(ownCenter);
    }
  }, [ownCenter]);

  const updateSearchPrefs = (
    partialFilters: Partial<Filters>,
    center?: Location | null,
    zoom?: number | null
  ): void => {
    const currSearchPrefs = searchPrefsRef.current; // Necessary, otherwise the map bbox callback will cause this to be called with initial ref
    const currFilters = currSearchPrefs?.filters || DEFAULT_FILTERS;
    const nextSearchPrefs: Readonly<SearchPrefs> = Object.freeze({
      center2: center || currSearchPrefs?.center2 || null,
      zoom: zoom || currSearchPrefs?.zoom,
      showResultsStrip: currSearchPrefs?.showResultsStrip,
      showFilters,
      filters: {
        ...currFilters,
        ...partialFilters,
        version: currFilters.version + 1,
      },
    });

    if (!isEqual(currSearchPrefs, nextSearchPrefs)) {
      searchPrefsRef.current = nextSearchPrefs;
      setSearchPrefs(nextSearchPrefs);
    }
  };

  const debouncedSearch = useMemo(() => {
    return debounce(args => {
      log.info(`Searching for ${JSON.stringify(filters)}`);
      const searchArgs = {
        ...args,
        context: {
          ...(args.context || {}),
          requestTrackerId: uuidNameSpace("SEARCH", RequestNameSpace),
        },
      };

      return executeSearch(searchArgs);
    }, 600);
  }, []);

  const refreshSearch = () => {
    if (
      !filters.bounds ||
      !filters.bounds.xmax ||
      !filters.bounds.xmin ||
      !filters.bounds.ymax ||
      !filters.bounds.ymin
    ) {
      // Can't search without bounds
      log.warn("Aborting search because bounds are not set");
      return;
    }

    const variables: any = {
      term: filters.term,
      xmax: filters.bounds.xmax,
      xmin: filters.bounds.xmin,
      ymax: filters.bounds.ymax,
      ymin: filters.bounds.ymin,
      zoom: filters.zoom,
      beginDate: filters.dates?.begin,
      endDate: filters.dates?.end,
      nights: filters.nights,
      people: filters.people,
      strict: filters.strict,
      firesAllowed: filters.firesAllowed,
      petsAllowed: filters.petsAllowed,
      accessible: filters.accessible,
      excludeGroup: filters.excludeGroup,
      onlyMatches: filters.onlyMatches,
      page: 1,
      pageSize: 500,
      facetSize: 100,
      cluster: true,
    };

    if (filters.dates) {
      variables.beginDate = filters.dates.begin;
      variables.endDate = filters.dates.end;
    }

    debouncedSearch({variables});
  };

  useEffect(() => {
    track("Search Page Viewed");
  }, []);

  // Search when filters changed
  useEffect(() => {
    // log.info(`Filters changed: ${JSON.stringify(searchPrefs)}`);

    refreshSearch();
  }, [filters]);

  // Search results updated
  useEffect(() => {
    if (!searchLoading && !searchError && searchResult.results) {
      log.info(`Search returned ${searchResult.results.length} results`);
      setResults(searchResult.results);
    }
  }, [searchLoading, searchError, searchResult]);

  // Filters shown/hidden
  useEffect(() => {
    setSearchPrefs({...searchPrefs, showFilters});
  }, [showFilters]);

  const popoverProps: Partial<SearchPopoverProps> = {filters};
  const popoverContainerStyle: any = {
    top: 0,
    left: 0,
  };

  // Show overlay at clicked location
  if (selection && selection.pixel && mapRect) {
    let [x, y] = selection.pixel;

    // Recalculate if pixel is too close to right or bottom boundary
    // const overlayRect = overlay.getBoundingClientRect();

    const overlayMaxWidth = 610; // overlayRect.width;
    if (x + overlayMaxWidth > mapRect.right) {
      x = mapRect.right - overlayMaxWidth;
    }

    const overlayMaxHeight = 555;
    if (y + overlayMaxHeight > mapRect.bottom) {
      y = mapRect.bottom - overlayMaxHeight;
    }

    popoverContainerStyle.top = y;
    popoverContainerStyle.left = x;
  }

  const hoverContainerStyle: any = {
    top: 0,
    left: 0,
  };

  // Show tooltip at hovered location
  if (hover && hover.pixel && mapRect) {
    let [x, y] = hover.pixel;

    // Recalculate if pixel is too close to right or bottom boundary
    // const overlayRect = overlay.getBoundingClientRect();

    const overlayMaxWidth = 200; // overlayRect.width;
    if (x + overlayMaxWidth > mapRect.right) {
      x = mapRect.right - overlayMaxWidth;
    }

    const overlayMaxHeight = 100;
    if (y + overlayMaxHeight > mapRect.bottom) {
      y = mapRect.bottom - overlayMaxHeight;
    }

    hoverContainerStyle.top = y;
    hoverContainerStyle.left = x;
  }

  let truncatedResults = false;
  const pagination = searchResult.pagination;
  if (pagination) {
    truncatedResults = pagination.totalResults > pagination.pageSize;
  }

  return (
    <div className={classes.search}>
      <div className={classes.buttonBar}>
        <Group>
          <PlacePicker
            size="sm"
            location={mapCenter || ownCenter}
            onSelection={place => {
              if (!place.location) {
                log.error(
                  `Unable to center map because selected place ${place.id} has no location`
                );
                return;
              }
              setMapCenter(place.location);
            }}
          />
          <Button.Group>
            {!resultsStripOpen && (
              <Button
                size="sm"
                variant="default"
                leftSection={<FontAwesomeIcon icon={faList} />}
                onClick={() => setResultsStripOpen(true)}>
                Results
              </Button>
            )}
            <Button
              onClick={() => {
                setShowFilters(!showFilters);
              }}
              title="Show filter options"
              size="sm"
              variant="default"
              leftSection={<FontAwesomeIcon icon={faFilter} />}>
              Filter
            </Button>
            <NoSSR>
              <Menu>
                <Menu.Target>
                  <Button
                    title="Location"
                    size="sm"
                    variant={position.watching ? "primary" : "default"}>
                    <FontAwesomeIcon
                      icon={position.watching ? faEye : faLocationDot}
                      beat={position.watching || position.updating}
                    />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    leftSection={<FontAwesomeIcon icon={faLocationDot} />}
                    onClick={event => {
                      event.preventDefault();
                      position.updatePosition();
                    }}>
                    My location
                  </Menu.Item>
                  <Menu.Item
                    leftSection={
                      <FontAwesomeIcon
                        icon={!position.watching ? faEye : faEyeSlash}
                      />
                    }
                    onClick={event => {
                      event.preventDefault();
                      position.watchPosition(!position.watching);
                    }}>
                    {!position.watching
                      ? "Live track location"
                      : "Stop live tracking location"}
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            </NoSSR>
          </Button.Group>
        </Group>
      </div>

      <div className={classes.results}>
        {/*resultsStripOpen && (
          <ResultsStrip
            results={results || []}
            onClose={() => setResultsStripOpen(false)}
            onSelection={(result, pixel) => {
              const [x, y] = pixel ? pixel : [0, 0];
              setSelection({
                result,
                pixel: [x, y - 55], // Compensate for offset of search results element screen position
              });
            }}
          />
        )*/}

        <MapView
          center={mapCenter}
          self={ownCenter}
          initialZoom={searchPrefs?.zoom}
          resultList={[...results]}
          satelliteView={true}
          onBoundsChanged={(
            lowerLeftX,
            lowerLeftY,
            upperRightX,
            upperRightY,
            center,
            zoom,
            mapRect
          ) => {
            if (center) {
              setMapCenter(center);
            }

            updateSearchPrefs(
              {
                bounds: {
                  xmin: lowerLeftX,
                  ymin: lowerLeftY,
                  xmax: upperRightX,
                  ymax: upperRightY,
                },
                zoom,
              },
              center,
              zoom
            );
            setMapRect(mapRect);
          }}
          onSelection={setSelection}
          onHover={setHover}
        />

        {hover && hover.results.length > 0 && (
          <div className={classes.hoverContainer} style={hoverContainerStyle}>
            <ul>
              {hover.results.map(result => {
                if (result.__typename && result.__typename === "MapCluster") {
                  const cluster = result as MapCluster;
                  return (
                    <li
                      key={`${cluster.__typename}:${cluster.location?.latitude}:${cluster.location?.longitude}`}>
                      <span>{cluster.count.toLocaleString()} camps</span>
                    </li>
                  );
                }

                const marker = result as SearchResult;
                return (
                  <li key={`${marker.type}:${marker.id}`}>
                    <span>{marker.name || `Unnamed #${marker.id}`}</span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

        {selection && selection.result && (
          <div
            className={classes.popoverContainer}
            style={popoverContainerStyle}>
            {selection.result.type === "Unit" && (
              <UnitPopover
                unit={{
                  id: parseInt(selection.result.id, 10),
                  name: selection.result.name,
                  __typename: selection.result.type,
                }}
                onClose={() => setSelection(null)}
                {...popoverProps}
              />
            )}
            {selection.result.type === "Facility" && (
              <FacilityPopover
                facility={{
                  id: parseInt(selection.result.id, 10),
                  name: selection.result.name,
                  __typename: selection.result.type,
                }}
                onClose={() => setSelection(null)}
                onWatchUpdated={() => refreshSearch()}
                {...popoverProps}
              />
            )}
            {selection.result.type === "Park" && (
              <ParkPopover
                park={{
                  id: parseInt(selection.result.id, 10),
                  name: selection.result.name,
                  __typename: selection.result.type,
                }}
                onClose={() => setSelection(null)}
                {...popoverProps}
              />
            )}
          </div>
        )}

        {truncatedResults && (
          <Alert
            className={classes.truncatedResults}
            variant="filled"
            color="orange"
            title="Zoom in to see more"
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}>
            There&apos;s too many results to display on the map.
            <br />
            Zoom the map in closer to see more.
          </Alert>
        )}

        {searchError && (
          <div className={classes.error}>
            <Alert
              variant="filled"
              color="red"
              icon={
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  size="lg"
                  beatFade
                />
              }>
              {searchError.message}
            </Alert>
          </div>
        )}

        {searchLoading && !position.watching && <SearchLoader />}
      </div>

      <Drawer
        position="right"
        opened={showFilters}
        onClose={() => {
          setShowFilters(false);
          if (typeof window !== "undefined") {
            // Window seems to resize on mobile, so this jumps to the top
            // so that the header doesn't disappear
            window.scrollTo(0, 0);
          }
        }}>
        <FilterPanel
          initialFilters={filters}
          searching={searchLoading}
          onChange={filters => {
            setShowFilters(false);
            updateSearchPrefs(filters);
          }}
        />
      </Drawer>
    </div>
  );
}
