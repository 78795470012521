import {track} from "@amplitude/analytics-browser";
import {useQuery} from "@apollo/client";
import {
  faExclamationTriangle,
  faLock,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Button,
  Checkbox,
  CloseButton,
  Fieldset,
  NumberInput,
  SimpleGrid,
  Space,
  Stack,
  TextInput,
  Tooltip,
  rem,
} from "@mantine/core";
import {DatesProvider, DatePickerInput} from "@mantine/dates";
import {IconCalendar} from "@tabler/icons-react";
import dayjs from "dayjs";
import {Formik, FormikErrors} from "formik";
import {noop, isEmpty, pick} from "lodash";
import Link from "next/link";
import React, {useState, useEffect} from "react";

import classes from "./FilterPanel.module.scss";
import type {Filters} from "./Filters";
import * as queries from "../../graphql/queries";
import {blanksAsNulls} from "../../utils";
import {useAuth} from "../contexts/AuthContext";

// Filters handled by this control
const HANDLED_FILTERS: readonly string[] = Object.freeze([
  "term",
  "dates",
  "nights",
  "people",
  "strict",
  "firesAllowed",
  "petsAllowed",
  "accessible",
  "excludeFCFS",
  "excludeGroup",
  "excludeUnreleased",
  "onlyMatches",
]);

export interface Props {
  initialFilters: Partial<Filters>;
  searching: boolean;
  onChange?(filters: Partial<Filters>): void;
}

const CLEAR_VALUES = {
  term: null,
  dates: {
    begin: null,
    end: null,
  },
  nights: null,
  people: null,
  strict: false,
  firesAllowed: false,
  petsAllowed: false,
  accessible: false,
  excludeFCFS: false,
  excludeGroup: false,
  excludeUnreleased: false,
  onlyMatches: false,
};

export default function FilterPanel({
  searching = false,
  onChange = noop,
  initialFilters,
}: Props) {
  const {authentication} = useAuth();
  const [isFiltering, setFiltering] = useState<boolean>(false);
  const {data: {search: {maxDate = undefined} = {}} = {}} = useQuery(
    queries.GET_MAX_DATE
  );
  const {data: {features: {isEnabled: filterGate = undefined} = {}} = {}} =
    useQuery(queries.GET_FEATURE_FLAG, {
      variables: {flag: "FILTER_GATE"},
    });

  const today = dayjs();
  const dateFormat = "YYYY-MM-DD";

  /*
  Minimum value for date inputs

  Workaround: Subtracting 1 day because Chrome and Firefox's built-in date picker seems to disable the current
  day towards the later part of the day in production, but it works fine locally. Must be something to do with
  time zones somewhere, but I'm unsure what's causing the behavior. Creating a `new Date()` on each site seems
  to return the same time zone.
  */
  const minDate: string = dayjs().subtract(1, "days").format("YYYY-MM-DD");

  useEffect(() => {
    if (isFiltering && !searching) {
      setFiltering(false);
    }
  }, [searching]);

  return (
    <div className={classes.filterPanel}>
      <h2>Filter</h2>
      <Formik<Partial<Filters>>
        initialValues={initialFilters}
        validate={values => {
          const errors: FormikErrors<Partial<Filters>> = {};

          // Validate date ranges and dependent fields
          if (values.dates?.begin || values.dates?.end) {
            if (!values.dates.begin || !values.dates.end) {
              errors.dates =
                "Both begin and end dates must be entered together";
            } else {
              const beginDate = dayjs(values.dates.begin, dateFormat);
              const endDate = dayjs(values.dates.end, dateFormat);
              const maxDateObj = maxDate ? dayjs(maxDate, dateFormat) : null;

              // today <= dates.begin
              if (beginDate.isBefore(today.startOf("day"))) {
                errors.dates = `Begin date is in the past: ${beginDate.format(
                  "L"
                )}`;
              }

              // Validate validate dates.end <= max(slots.date)
              if (maxDateObj && endDate.isAfter(maxDateObj)) {
                errors.dates = `We don't have reservation data beyond ${maxDateObj.format(
                  "L"
                )}`;
              }

              // dates.begin <= dates.end
              if (endDate.isSameOrAfter(beginDate)) {
                // nights is agreeable with date range
                if (
                  values.nights &&
                  beginDate.add(values.nights - 1, "days").isAfter(endDate)
                ) {
                  errors.nights =
                    "Number of nights is more than your selected date range";
                }
              } else {
                errors.dates =
                  "End date must be greater than or the same as begin date";
              }
            }

            //if (!values.nights) {
            //  errors.nights = "Nights are required when dates are entered";
            //}
          } else {
            if (values.nights) {
              errors.dates = "Dates are required when nights are entered";
            }
          }

          // Validate nights
          if (values.nights) {
            // TODO: Validate nights is valid between dates.begin and dates.end

            if (values.nights < 1) {
              errors.nights = "Number of nights must be at least 1";
            }
          }

          // Validate people
          if (values.people && values.people < 1) {
            errors.people = "Group size must be at least 1";
          }

          return errors;
        }}
        onSubmit={async (values, {setSubmitting}) => {
          const submitValues = blanksAsNulls(pick(values, HANDLED_FILTERS));
          track("Search Filtered", {
            filters: submitValues,
          });
          setFiltering(true);
          onChange(submitValues);
          setSubmitting(false);
        }}>
        {({
          handleSubmit,
          values,
          errors,
          dirty,
          isValidating,
          isSubmitting,
          submitCount,
          setFieldValue,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack>
              {/*
              <TextInput
                name="term"
                label="Term"
                error={submitCount > 0 && errors.term}
                placeholder="Search for anything..."
                value={values.term || ""}
                onChange={event => setFieldValue("term", event.target.value)}
              />
              */}
              <DatesProvider settings={{firstDayOfWeek: 0}}>
                <Tooltip
                  withArrow
                  label="Dates you are looking for a camp site.">
                  <DatePickerInput
                    type="range"
                    label="Dates"
                    error={submitCount > 0 && errors.dates}
                    defaultDate={today.toDate()}
                    excludeDate={date => {
                      const dateObj = dayjs(date);
                      if (dateObj.isBefore(today.startOf("day"))) {
                        return true;
                      }

                      if (maxDate) {
                        const maxDateObj = dayjs(maxDate, dateFormat);
                        return dateObj.isAfter(maxDateObj);
                      }

                      return false;
                    }}
                    valueFormat="L"
                    value={[
                      values.dates?.begin
                        ? dayjs(values.dates.begin, dateFormat).toDate()
                        : null,
                      values.dates?.end
                        ? dayjs(values.dates.end, dateFormat).toDate()
                        : null,
                    ]}
                    onChange={value => {
                      const [beginDate, endDate] = value;
                      setFieldValue(
                        "dates.begin",
                        beginDate ? dayjs(beginDate).format(dateFormat) : null
                      );
                      setFieldValue(
                        "dates.end",
                        endDate ? dayjs(endDate).format(dateFormat) : null
                      );
                    }}
                    clearable
                    leftSection={
                      <IconCalendar
                        style={{width: rem(18), height: rem(18)}}
                        stroke={1.5}
                      />
                    }
                    leftSectionPointerEvents="none"
                  />
                </Tooltip>
              </DatesProvider>

              <SimpleGrid cols={2}>
                <Tooltip
                  withArrow
                  label="Minimum number of consecutive nights you want to stay.">
                  <NumberInput
                    name="nights"
                    label="Nights"
                    error={submitCount > 0 && errors.nights}
                    min={1}
                    value={values.nights || ""}
                    onChange={value => setFieldValue("nights", value)}
                    leftSection={
                      <CloseButton
                        aria-label="Clear input"
                        onClick={() => setFieldValue("nights", "")}
                        style={{display: !!values.nights ? undefined : "none"}}
                      />
                    }
                  />
                </Tooltip>

                <Tooltip
                  withArrow
                  label="Maximum number of people in your group.">
                  <NumberInput
                    name="people"
                    label="Group size"
                    error={submitCount > 0 && errors.people}
                    min={1}
                    value={values.people || ""}
                    onChange={value => setFieldValue("people", value)}
                    leftSection={
                      <CloseButton
                        aria-label="Clear input"
                        onClick={() => setFieldValue("people", "")}
                        style={{display: !!values.people ? undefined : "none"}}
                      />
                    }
                  />
                </Tooltip>
              </SimpleGrid>

              <Fieldset legend="Include">
                <Tooltip
                  withArrow
                  label={
                    <div>
                      <strong>Strictly match search criteria</strong>
                      <div>
                        If camp sites are missing data for the specific criteria
                        you are searching for, they will be excluded as if they
                        did not match your search criteria at all.
                      </div>
                    </div>
                  }>
                  <Checkbox
                    name="strict"
                    label="Strict matching"
                    error={submitCount > 0 && errors.strict}
                    checked={values.strict}
                    onChange={event =>
                      setFieldValue("strict", event.target.checked)
                    }
                  />
                </Tooltip>
                <Space h="sm" />
                <Checkbox name="firesAllowed" label="Fires allowed" disabled />
                <Space h="sm" />
                <Tooltip
                  withArrow
                  label="Only include camp sites that are pet-friendly.">
                  <Checkbox
                    name="petsAllowed"
                    label="Pets allowed"
                    error={submitCount > 0 && errors.petsAllowed}
                    checked={values.petsAllowed}
                    onChange={event =>
                      setFieldValue("petsAllowed", event.target.checked)
                    }
                  />
                </Tooltip>
                <Space h="sm" />
                <Tooltip
                  withArrow
                  label="Only include camp sites that are accessible to those with disabilities.">
                  <Checkbox
                    name="accessible"
                    label="ADA accessible"
                    error={submitCount > 0 && errors.accessible}
                    checked={values.accessible}
                    onChange={event =>
                      setFieldValue("accessible", event.target.checked)
                    }
                  />
                </Tooltip>
              </Fieldset>
              <Fieldset legend="Exclude">
                <Tooltip
                  withArrow
                  label="Exclude group camp sites. These are usually more expensive sites that can host larger groups.">
                  <Checkbox
                    name="excludeGroup"
                    label="Group camp sites"
                    error={submitCount > 0 && errors.excludeGroup}
                    checked={values.excludeGroup}
                    onChange={event =>
                      setFieldValue("excludeGroup", event.target.checked)
                    }
                  />
                </Tooltip>
                <Space h="sm" />
                <Tooltip
                  withArrow
                  label="Exclude camp sites that can't be reserved in advance (e.g. walk-in only, first come first served).">
                  <Checkbox
                    name="excludeFCFS"
                    label="Non-reservable camp sites"
                    error={submitCount > 0 && errors.excludeFCFS}
                    checked={values.excludeFCFS}
                    onChange={event =>
                      setFieldValue("excludeFCFS", event.target.checked)
                    }
                  />
                </Tooltip>
                <Space h="sm" />
                <Tooltip
                  withArrow
                  label="Exclude camp sites that had canceled reservations, but haven't been released yet.">
                  <Checkbox
                    name="excludeUnreleased"
                    label="Unreleased camp sites"
                    error={submitCount > 0 && errors.excludeUnreleased}
                    checked={values.excludeUnreleased}
                    onChange={event =>
                      setFieldValue("excludeUnreleased", event.target.checked)
                    }
                  />
                </Tooltip>
              </Fieldset>

              <Fieldset legend="Options">
                <Tooltip withArrow label="Only show matching results.">
                  <Checkbox
                    name="onlyMatches"
                    label="Hide results that don't match"
                    error={submitCount > 0 && errors.onlyMatches}
                    checked={values.onlyMatches}
                    onChange={event =>
                      setFieldValue("onlyMatches", event.target.checked)
                    }
                  />
                </Tooltip>
              </Fieldset>

              <div className={classes.searchButtonContainer}>
                <Button.Group>
                  <Tooltip
                    withArrow
                    label={isEmpty(errors) ? "Search" : "Please correct errors"}
                    color={isEmpty(errors) ? undefined : "red"}>
                    <Button
                      variant="primary"
                      onClick={() => handleSubmit()}
                      disabled={isValidating || isSubmitting || isFiltering}
                      loading={isFiltering}
                      leftSection={
                        !isFiltering && !isEmpty(errors) && submitCount > 0 ? (
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            beatFade
                          />
                        ) : (
                          <FontAwesomeIcon icon={faFilter} />
                        )
                      }>
                      Apply
                    </Button>
                  </Tooltip>
                  <Button
                    variant="default"
                    onClick={() => resetForm({values: CLEAR_VALUES})}>
                    Clear
                  </Button>
                </Button.Group>
              </div>
            </Stack>

            {!!filterGate && authentication.status !== "SIGNED_IN" && (
              <div className={classes.signinNag}>
                <div>
                  <FontAwesomeIcon icon={faLock} />{" "}
                  <Link href="/signIn">Sign in</Link> or{" "}
                  <Link href="/signUp">sign up</Link> to use filters
                </div>
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
}
