import {track} from "@amplitude/analytics-browser";
import {faTicket} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, MantineSize} from "@mantine/core";
import Link from "next/link";

import type {Facility} from "../graphql/types";

export interface Props {
  facility: Partial<Facility>;
  title?: string | null;
  source?: string;
  size?: MantineSize;
}

export default function ReserveFacilityButton({
  facility,
  title,
  source,
  size = "lg",
}: Props) {
  function handleClick() {
    track("Reserve Facility Button Clicked", {
      facility: {
        id: facility.id,
        name: facility.name,
      },
      source,
    });
  }

  if (title === undefined) {
    title = "Reserve";
  }

  return (
    <Button
      variant="primary"
      className="reserve-facility-button"
      size={size}
      disabled={!facility.bookingUrl}
      component={Link}
      href={facility.bookingUrl || "#"}
      target="_blank"
      onClick={handleClick}
      leftSection={<FontAwesomeIcon icon={faTicket} />}>
      {title}
    </Button>
  );
}
