import Image from "next/image";

import classes from "./SearchLoader.module.scss";

export default function SearchLoader() {
  return (
    <div className={classes.searchLoader}>
      <Image
        src="/images/searching.gif"
        alt="Searching..."
        fill
        unoptimized
        priority
      />
    </div>
  );
}
