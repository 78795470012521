import {Button, Tabs, Card, Text} from "@mantine/core";
import classNames from "classnames";
import parse from "html-react-parser";
import {isEmpty, noop} from "lodash";
import Link from "next/link";

import classes from "./Popover.module.scss";
import {SearchPopoverProps} from "./types";
import type {Park} from "../../graphql/types";
import MediaCarousel from "../MediaCarousel";
import WatchButton from "../WatchButton";

export interface Props extends SearchPopoverProps {
  park: Partial<Park>;
}

export default function ParkPopover({park, onClose = noop}: Props) {
  let defaultTab: string;
  if (!isEmpty(park.media)) {
    defaultTab = "photos";
  } else {
    defaultTab = "details";
  }

  const parkName = park.name || `Unnamed #${park.id}`;

  let description = park.descriptionHtml
    ? parse(park.descriptionHtml || "")
    : park.description;

  if (description === park.name) {
    description = null;
  }

  return (
    <Card className={classNames(classes.popover, "park-popover")}>
      <Card.Section className={classes.header}>
        <h4>
          <Link
            href={`/parks/${park.id}`}
            title={parkName}
            legacyBehavior
            passHref>
            <a>{parkName}</a>
          </Link>
          <a
            className={classes.closeButton}
            href="#"
            title="Close"
            onClick={event => {
              event.preventDefault();
              onClose();
            }}>
            &times;
          </a>
        </h4>
      </Card.Section>
      <Card.Section className={classes.body}>
        <Tabs defaultValue={defaultTab}>
          <Tabs.List>
            <Tabs.Tab value="details">Description</Tabs.Tab>
            {!isEmpty(park.media) && <Tabs.Tab value="photos">Photos</Tabs.Tab>}
            <Tabs.Tab value="facilities">Facilities</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="details" className={classes.tabContent}>
            {description ? (
              <div className="embedded-html">
                {park.descriptionHtml
                  ? parse(park.descriptionHtml)
                  : park.description || "No description available."}
              </div>
            ) : (
              <Text size="sm">No description available.</Text>
            )}
          </Tabs.Panel>
          {!isEmpty(park.media) && (
            <Tabs.Panel value="photos" className={classes.tabContent}>
              <MediaCarousel small mediaList={park.media || []} />
            </Tabs.Panel>
          )}
          <Tabs.Panel value="facilities" className={classes.tabContent}>
            Coming soon
          </Tabs.Panel>
        </Tabs>
      </Card.Section>
      <Card.Section className={classes.footer}>
        <Button.Group>
          <Button
            variant="default"
            size="sm"
            component={() => <a href={`/parks/${park.id}`} />}>
            Details
          </Button>
          <WatchButton target={park} />
        </Button.Group>
      </Card.Section>
    </Card>
  );
}
