import {useQuery} from "@apollo/client";
import {
  faExclamationTriangle,
  faCalendarDays,
  faTents,
  faImage,
  faBook,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  Card,
  Center,
  Checkbox,
  Container,
  Space,
  Tabs,
  Text,
} from "@mantine/core";
import classNames from "classnames";
import dayjs from "dayjs";
import parse from "html-react-parser";
import {isEmpty, noop} from "lodash";
import Link from "next/link";
import {useState} from "react";

import facilityClasses from "./FacilityPopover.module.scss";
import classes from "./Popover.module.scss";
import {SearchPopoverProps} from "./types";
import * as constants from "../../constants";
import * as queries from "../../graphql/queries";
import type {Facility, Watch} from "../../graphql/types";
import {Permissions} from "../../graphql/types";
import {useAuth} from "../contexts/AuthContext";
import FacilityUnitsTable from "../FacilityUnitsTable";
import Loader from "../Loader";
import MediaCarousel from "../MediaCarousel";
import ReserveFacilityButton from "../ReserveFacilityButton";
import {filtersToQueryVariables} from "../Search/Filters";
import WatchButton from "../WatchButton";

const DEFAULT_SLOTS_DAYS = 14;

export interface Props extends SearchPopoverProps {
  facility: Partial<Facility>;
  defaultTab?: "details" | "photos" | "units";
  onWatchUpdated?(watch: Watch): void;
}

export default function FacilityPopover({
  facility: initialFacility,
  defaultTab,
  filters,
  onClose = noop,
  onWatchUpdated = noop,
}: Props) {
  const {hasPermission} = useAuth();
  const beginDate = filters?.dates?.begin || dayjs().format("YYYY-MM-DD");
  const endDate =
    filters?.dates?.end ||
    dayjs().add(DEFAULT_SLOTS_DAYS, "days").format("YYYY-MM-DD");
  const querySlots = !!(beginDate && endDate);
  const [filterUnits, setFilterUnits] = useState<boolean>(querySlots);

  let query: any;
  let queryVars: any = {id: initialFacility.id};

  if (querySlots) {
    if (filterUnits) {
      query = queries.GET_FACILITY_DETAILS_WITH_FILTERED_UNITS_AND_SLOTS;
      queryVars = {
        ...queryVars,
        ...(filters ? filtersToQueryVariables(filters) : {}),
        onlyMatches: true,
        beginDate,
        endDate,
      };
    } else {
      query = queries.GET_FACILITY_DETAILS_WITH_SLOTS;
      queryVars = {
        ...queryVars,
        beginDate,
        endDate,
      };
    }
  } else {
    query = queries.GET_FACILITY_DETAILS;
  }

  const {
    loading: facilityLoading,
    error: facilityError,
    data: {entities: {facility: facilityDetails = undefined} = {}} = {},
  } = useQuery(query, {
    variables: queryVars,
  });

  const facility: Partial<Facility> = facilityDetails || initialFacility;

  let facilityUrl = `/facilities/${facility?.id}`;
  if (querySlots) {
    facilityUrl = `${facilityUrl}?beginDate=${dayjs(beginDate).format(
      constants.URL_DATE_FORMAT
    )}&endDate=${dayjs(endDate).format(constants.URL_DATE_FORMAT)}`;
  }

  const facilityName = facility.name || `Unnamed #${facility.id}`;

  let description = facility.descriptionHtml
    ? parse(facility.descriptionHtml || "")
    : facility.description;

  if (description === facility.name) {
    description = null;
  }

  const now = dayjs();
  const staleDate = now.subtract(constants.SLOT_STALE_THRESHOLD_DAYS, "days");
  const lastCheckedAt = facility.lastCheckedAt
    ? dayjs(facility.lastCheckedAt)
    : null;
  const isStale = !lastCheckedAt || lastCheckedAt.isBefore(staleDate);

  const addWatchMessage = (
    <Text size="sm">
      Want to see more recent reservation availability? <strong>Watch</strong>{" "}
      this campground so that it gets checked more frequently!
    </Text>
  );

  if (!defaultTab) {
    defaultTab = "units";
  }

  let filterUnitsInput = null;
  if (querySlots) {
    filterUnitsInput = (
      <div className={facilityClasses.filterUnitsCheckbox}>
        <Checkbox
          size="xs"
          label={`Only show camp sites that match my filters (of ${(
            facility.unitCount || 0
          ).toLocaleString()} total)`}
          checked={filterUnits}
          onChange={event => setFilterUnits(event.target.checked)}
        />
      </div>
    );
  }

  let unitsBody;
  if (!isEmpty(facility.units)) {
    unitsBody = (
      <div>
        {filterUnitsInput}
        {isStale && (
          <Alert
            title={`Reservation availability was ${
              lastCheckedAt
                ? `checked ${lastCheckedAt.fromNow()}`
                : "never checked"
            }`}
            color="yellow">
            {addWatchMessage}
          </Alert>
        )}
        <FacilityUnitsTable
          facility={facility}
          beginDate={beginDate}
          endDate={endDate}
        />
      </div>
    );
  } else if (querySlots && facility.unitCount) {
    unitsBody = (
      <div>
        {filterUnitsInput}
        <Space h="md" />
        <Container>
          <Center>
            <img
              className={facilityClasses.noVacancyImage}
              src="/images/no_vacancy.png"
            />
            <div>
              <Text size="md">
                {(facility.unitCount || 0) == 1 ? (
                  <span>
                    <strong>The camp site</strong> at {facility.name} isn&apos;t
                    available
                  </span>
                ) : (
                  <span>
                    <strong>
                      None of the {(facility.unitCount || 0).toLocaleString()}{" "}
                      camp sites
                    </strong>{" "}
                    at {facility.name} are available
                  </span>
                )}
                <span> with the filters you specified.</span>
              </Text>
              {lastCheckedAt && (
                <>
                  <Space h="md" />
                  <Text size="sm">
                    Reservation availability was checked{" "}
                    <strong>{lastCheckedAt.fromNow()}</strong>.
                  </Text>
                </>
              )}
              {isStale && (
                <>
                  <Space h="md" />
                  {addWatchMessage}
                </>
              )}
              <Space h="md" />
              <Text size="xs">
                <strong>Tip:</strong> Try looking for other campgrounds,
                adjusting your filters to be less strict, or{" "}
                <a
                  href="#"
                  onClick={event => {
                    event.preventDefault();
                    setFilterUnits(false);
                  }}>
                  showing all sites
                </a>
                .
              </Text>
            </div>
          </Center>
        </Container>
      </div>
    );
  } else {
    unitsBody = (
      <Container>
        <Center>
          <img
            className={facilityClasses.noVacancyImage}
            src="/images/no_vacancy.png"
          />
          <div>
            <Text size="md">
              <strong>We don&apos;t have reservation availability yet</strong>{" "}
              for camp sites at {facility.name}.
            </Text>
            <Space h="md" />
            <Text size="sm">
              Interested in seeing what camp sites you can reserve here?{" "}
              <strong>Watch</strong> this campground so we can start checking it
              for you.
            </Text>
            <Space h="md" />
            {facility.lastCheckedAt && (
              <>
                <Text size="sm">
                  Reservation availability was checked{" "}
                  <strong>{dayjs(facility.lastCheckedAt).fromNow()}</strong>.
                </Text>
                <Space h="md" />
              </>
            )}
            <Text size="xs">
              <strong>Tip:</strong> Try looking for other campgrounds, adjusting
              your filters to be less strict, or watching this campground and
              checking back later.
            </Text>
          </div>
        </Center>
      </Container>
    );
  }

  let cardBody;
  if (facilityLoading) {
    cardBody = <Loader centered />;
  } else if (facilityError) {
    cardBody = (
      <Alert
        variant="filled"
        color="red"
        icon={<FontAwesomeIcon icon={faExclamationTriangle} />}>
        {facilityError.message}
      </Alert>
    );
  } else {
    cardBody = (
      <Tabs defaultValue={defaultTab}>
        <Tabs.List>
          <Tabs.Tab
            value="details"
            leftSection={<FontAwesomeIcon icon={faBook} />}>
            Description
          </Tabs.Tab>
          {!isEmpty(facility.media) && (
            <Tabs.Tab
              value="photos"
              leftSection={<FontAwesomeIcon icon={faImage} />}>
              Photos
            </Tabs.Tab>
          )}
          <Tabs.Tab
            value="units"
            leftSection={<FontAwesomeIcon icon={faTents} />}>
            Camp Sites
          </Tabs.Tab>
          {hasPermission(Permissions.Admin) && (
            <Tabs.Tab
              value="scan-history"
              leftSection={<FontAwesomeIcon icon={faCalendarDays} />}>
              Scan History
            </Tabs.Tab>
          )}
        </Tabs.List>

        <Tabs.Panel value="details" className={classes.tabContent}>
          {description ? (
            <div className="embedded-html">
              {facility.descriptionHtml
                ? parse(facility.descriptionHtml)
                : facility.description || ""}
            </div>
          ) : (
            <Text size="sm">No description available.</Text>
          )}
        </Tabs.Panel>

        {!isEmpty(facility.media) && (
          <Tabs.Panel value="photos" className={classes.tabContent}>
            <MediaCarousel small mediaList={facility.media || []} />
          </Tabs.Panel>
        )}

        <Tabs.Panel value="units" className={classes.tabContent}>
          {unitsBody}
        </Tabs.Panel>

        {hasPermission(Permissions.Admin) && (
          <Tabs.Panel value="scan-history" className={classes.tabContent}>
            <Text size="md">
              {facility.lastCheckedAt ? (
                <span>
                  Reservation availability was checked{" "}
                  <strong title={facility.lastCheckedAt}>
                    {dayjs(facility.lastCheckedAt).fromNow()}
                  </strong>
                  .
                </span>
              ) : (
                <span>
                  Reservation availability was <strong>never</strong> checked.
                </span>
              )}
            </Text>
          </Tabs.Panel>
        )}
      </Tabs>
    );
  }

  return (
    <Card className={classNames(classes.popover, "facility-popover")}>
      <Card.Section className={classes.header}>
        <h4>
          <a
            className={classes.closeButton}
            href="#"
            title="Close"
            onClick={event => {
              event.preventDefault();
              onClose();
            }}>
            &times;
          </a>
          <div className={classes.parent}>
            {facility.park ? (
              <Link
                href={`/parks/${facility.park.id}`}
                title={facility.park.name || ""}
                legacyBehavior
                passHref>
                <a>{facility.park.name}</a>
              </Link>
            ) : (
              <span>&nbsp;</span>
            )}
          </div>
          <Link href={facilityUrl} title={facilityName} legacyBehavior passHref>
            <a>{facilityName}</a>
          </Link>
        </h4>
      </Card.Section>
      <Card.Section className={classes.body}>{cardBody}</Card.Section>
      <Card.Section className={classes.footer}>
        <Button.Group>
          <ReserveFacilityButton
            facility={facility}
            size="sm"
            source="facility_popover"
          />
          <WatchButton target={facility} onWatchUpdated={onWatchUpdated} />
        </Button.Group>
      </Card.Section>
    </Card>
  );
}
