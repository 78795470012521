import {useLazyQuery} from "@apollo/client";
import {
  faMagnifyingGlass,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Alert,
  CloseButton,
  Combobox,
  InputBase,
  Loader,
  MantineSize,
  useCombobox,
} from "@mantine/core";
import {debounce, groupBy, capitalize, noop} from "lodash";
import {useState, useEffect, useMemo} from "react";

import classes from "./PlacePicker.module.scss";
import * as queries from "../graphql/queries";
import {Place, Location} from "../graphql/types";
import {getLogger} from "../logging";

const log = getLogger(__filename);

export interface Props {
  size?: MantineSize;
  location?: Location | null;
  onSelection?(place: Place): void;
}

export default function PlacePicker({
  size = "sm",
  location = null,
  onSelection = noop,
}: Props) {
  const [search, setSearch] = useState<string>("");
  const [value, setValue] = useState<string | null>(null);
  const [
    executeSearch,
    {
      loading: searchLoading,
      error: searchError,
      data: {places: {search: searchResult = undefined} = {}} = {},
    },
  ] = useLazyQuery(queries.SEARCH_PLACES);
  const [getPlaceLocation, _getPlaceLocationResult] = useLazyQuery(
    queries.GET_PLACE_LOCATION
  );

  const debouncedSearch = useMemo(() => {
    return debounce(term => {
      if (!term) {
        return;
      }

      log.info(`Searching for place: ${term}`);
      return executeSearch({
        variables: {
          term,
          near:
            location?.latitude && location?.longitude
              ? {
                  latitude: location.latitude || 0,
                  longitude: location.longitude || 0,
                }
              : undefined,
        },
      });
    }, 600);
  }, [location]);

  useEffect(() => {
    debouncedSearch(search);
  }, [search]);

  const comboBox = useCombobox({
    onDropdownClose: () => {
      comboBox.resetSelectedOption();
    },
  });

  const places: Place[] | void = searchResult?.results;
  const placesById: {[id: string]: Place} = {};
  for (const place of places || []) {
    placesById[place.id] = place;
  }

  useEffect(() => {
    if (places) {
      comboBox.selectFirstOption();
    }
  }, [searchResult]);

  let options;
  if (searchLoading) {
    options = <Combobox.Empty>Searching...</Combobox.Empty>;
  } else if (searchError) {
    options = (
      <Combobox.Empty>
        <Alert
          variant="filled"
          color="red"
          icon={<FontAwesomeIcon icon={faExclamationTriangle} beatFade />}>
          {searchError.message}
        </Alert>
      </Combobox.Empty>
    );
  } else if (places && places.length > 0) {
    const grouped = groupBy(places, (place: Place) => place.placeType);
    options = Object.keys(grouped).map(placeType => {
      let groupLabel: string;
      if (placeType === "poi") {
        groupLabel = "Point of Interest";
      } else {
        groupLabel = capitalize(placeType);
      }

      return (
        <Combobox.Group label={groupLabel} key={placeType}>
          {grouped[placeType].map((place: Place) => {
            let regionLabel: string | null = place.region || null;
            if (regionLabel) {
              regionLabel = regionLabel.replace(/, United States$/, "");
            }

            return (
              <Combobox.Option value={place.id} key={place.id}>
                <div className={classes.placeName}>{place.name}</div>
                {regionLabel && (
                  <div className={classes.placeRegion}>{regionLabel}</div>
                )}
              </Combobox.Option>
            );
          })}
        </Combobox.Group>
      );
    });
  } else if (places && places.length === 0) {
    options = <Combobox.Empty>No results found</Combobox.Empty>;
  } else {
    options = null;
  }

  let rightSection;
  if (searchLoading) {
    rightSection = <Loader size="sm" />;
  } else if (value) {
    rightSection = (
      <CloseButton
        size={size}
        onMouseDown={event => event.preventDefault()}
        onClick={() => {
          setValue("");
          setSearch("");
        }}
        aria-label="Clear value"
      />
    );
  } else {
    rightSection = <FontAwesomeIcon icon={faMagnifyingGlass} />;
  }

  return (
    <Combobox
      size={size}
      store={comboBox}
      withinPortal={false}
      onOptionSubmit={async placeId => {
        const place = placesById[placeId];
        if (!place) {
          log.error(`Place not cached for ID ${placeId}`);
          return;
        }

        comboBox.closeDropdown();

        // Select place
        setValue(placeId);
        setSearch(place.name || "");

        if (place.location) {
          // Place already has location
          onSelection(place);
        } else {
          // Lookup place location
          const {
            error: placeLocationError,
            data: {places: {location: placeLocation = undefined} = {}} = {},
          } = await getPlaceLocation({
            variables: {
              id: placeId,
              sessionToken: searchResult?.sessionToken,
            },
          });

          if (placeLocationError) {
            alert(
              `Error selecting place: ${JSON.stringify(placeLocationError)}`
            );
            return;
          }

          const placeClone = structuredClone(place);
          placeClone.location = placeLocation;
          onSelection(placeClone);
        }
      }}>
      <Combobox.Target>
        <InputBase
          className={classes.input}
          size={size}
          value={search}
          onChange={event => {
            comboBox.openDropdown();
            comboBox.updateSelectedOptionIndex();
            setSearch(event.currentTarget.value);
          }}
          onClick={() => comboBox.openDropdown()}
          onFocus={() => comboBox.openDropdown()}
          onBlur={() => comboBox.closeDropdown()}
          rightSection={rightSection}
          placeholder="Search..."
        />
      </Combobox.Target>
      {options && (
        <Combobox.Dropdown>
          <Combobox.Options>{options}</Combobox.Options>
        </Combobox.Dropdown>
      )}
    </Combobox>
  );
}
