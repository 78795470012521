import {Carousel} from "@mantine/carousel";
import classNames from "classnames";
import {isEmpty} from "lodash";
import React from "react";

import classes from "./MediaCarousel.module.scss";
import MediaImage from "./MediaImage";
import type {Media} from "../graphql/types";

export interface Props {
  mediaList: Partial<Media>[];
  small?: boolean;
}

export default function MediaCarousel({mediaList, small = false}: Props) {
  return (
    <>
      {!isEmpty(mediaList) && (
        <div>
          <Carousel
            height={small ? 310 : 512}
            withControls={mediaList.length > 1}
            withIndicators={mediaList.length > 1}
            className={classNames(classes.mediaCarousel, {small})}>
            {mediaList
              .filter((media: Partial<Media>) => media.url)
              .map((media: Partial<Media>) => (
                <Carousel.Slide key={media.id}>
                  <MediaImage media={media} />
                </Carousel.Slide>
              ))}
          </Carousel>
        </div>
      )}
    </>
  );
}
