import {Table} from "@mantine/core";
import classNames from "classnames";
import dayjs from "dayjs";
import {isEmpty, startCase, toLower, kebabCase} from "lodash";

import * as constants from "../constants";
import type {Unit} from "../graphql/types";

export interface Props {
  unit: Partial<Unit>;
}

export default function UnitSlotsTable({unit}: Props) {
  if (!unit.slots || isEmpty(unit.slots)) {
    return null;
  }

  const now = dayjs();
  const staleDate = now.subtract(constants.SLOT_STALE_THRESHOLD_DAYS, "days");

  return (
    <div className="unit-slots">
      <Table>
        <Table.Tbody>
          {unit.slots?.map(slot => {
            const slotDate = dayjs(slot.slotDate);
            const updatedAt = dayjs(slot.updatedAt);

            return (
              <Table.Tr key={slot.id}>
                <Table.Th>{slotDate.format("MM/DD ddd")}</Table.Th>
                <Table.Td
                  title={
                    startCase(toLower(slot.status || "unknown")) +
                    `\n(Checked ${updatedAt.fromNow()})`
                  }
                  className={classNames(
                    "slot",
                    `slot-status-${kebabCase(
                      toLower(slot.status || "unknown")
                    )}`,
                    {
                      "slot-stale": updatedAt.isBefore(staleDate),
                    }
                  )}>
                  {startCase(toLower(slot.status || "unknown"))}
                </Table.Td>
              </Table.Tr>
            );
          })}
        </Table.Tbody>
      </Table>
    </div>
  );
}
