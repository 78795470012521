import {useQuery} from "@apollo/client";
import {faExclamationTriangle, faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Alert, Button, Card, Tabs} from "@mantine/core";
import classNames from "classnames";
import dayjs from "dayjs";
import {isEmpty, noop} from "lodash";
import Link from "next/link";

import classes from "./Popover.module.scss";
import {SearchPopoverProps} from "./types";
import * as constants from "../../constants";
import * as queries from "../../graphql/queries";
import type {Unit} from "../../graphql/types";
import MediaCarousel from "../MediaCarousel";
import ReserveUnitButton from "../ReserveUnitButton";
import UnitSlotsTable from "../UnitSlotsTable";
import WatchButton from "../WatchButton";

export interface Props extends SearchPopoverProps {
  unit: Partial<Unit>;
  defaultTab?: "details" | "photos" | "availability";
}

export default function UnitPopover({
  unit: initialUnit,
  filters,
  defaultTab,
  onClose = noop,
}: Props) {
  const queryVars: any = {id: initialUnit.id};
  const querySlots = !!(filters?.dates?.begin && filters?.dates?.end);

  if (querySlots) {
    queryVars.beginDate = filters?.dates?.begin;
    queryVars.endDate = filters?.dates?.end;
  }

  const {
    error: unitError,
    data: {entities: {unit: unitDetails = undefined} = {}} = {},
  } = useQuery(
    querySlots ? queries.GET_UNIT_DETAILS_WITH_SLOTS : queries.GET_UNIT_DETAILS,
    {variables: queryVars}
  );

  const unit: Partial<Unit> = unitDetails || initialUnit;

  let unitUrl = `/units/${unit?.id}`;
  if (querySlots) {
    unitUrl = `${unitUrl}?beginDate=${dayjs(filters?.dates?.begin).format(
      constants.URL_DATE_FORMAT
    )}&endDate=${dayjs(filters?.dates?.end).format(constants.URL_DATE_FORMAT)}`;
  }

  if (!defaultTab) {
    if (!isEmpty(unit.slots)) {
      defaultTab = "availability";
    } else if (!isEmpty(unit.media)) {
      defaultTab = "photos";
    }
  }

  const unitName = `Camp site: ${unit.name || `Unnamed #${unit.id}`}`;

  return (
    <Card className={classNames(classes.popover, "unit-popover")}>
      <Card.Section className={classes.header}>
        <h4>
          <a
            className={classes.closeButton}
            href="#"
            title="Close"
            onClick={event => {
              event.preventDefault();
              onClose();
            }}>
            &times;
          </a>
          {unit.facility && (
            <div className={classes.parent}>
              {unit.facility.park && (
                <span>
                  <Link
                    href={`/parks/${unit.facility.park.id}`}
                    title={unit.facility.park.name || ""}
                    legacyBehavior
                    passHref>
                    <a>{unit.facility.park.name}</a>
                  </Link>
                  {" / "}
                </span>
              )}
              <Link
                href={`/facilities/${unit.facility.id}`}
                title={unit.facility.name || ""}
                legacyBehavior
                passHref>
                <a>{unit.facility.name}</a>
              </Link>
            </div>
          )}
          <Link href={unitUrl} title={unitName} legacyBehavior passHref>
            <a>{unitName}</a>
          </Link>
        </h4>
      </Card.Section>
      <Card.Section className={classes.body}>
        {unitError ? (
          <Alert
            variant="filled"
            color="red"
            icon={<FontAwesomeIcon icon={faExclamationTriangle} />}>
            {unitError.message}
          </Alert>
        ) : (
          <Tabs defaultValue={defaultTab}>
            <Tabs.List>
              {!isEmpty(unit.media) && (
                <Tabs.Tab value="photos">Photos</Tabs.Tab>
              )}
              <Tabs.Tab value="availability">Availability</Tabs.Tab>
            </Tabs.List>

            {!isEmpty(unit.media) && (
              <Tabs.Panel value="photos" className={classes.tabContent}>
                <MediaCarousel small mediaList={unit.media || []} />
              </Tabs.Panel>
            )}

            <Tabs.Panel value="availability" className={classes.tabContent}>
              {!querySlots && (
                <div>Select a date range to see availability.</div>
              )}
              {!isEmpty(unit.slots) && <UnitSlotsTable unit={unit} />}
            </Tabs.Panel>
          </Tabs>
        )}
      </Card.Section>
      <Card.Section className={classes.footer}>
        <Button.Group>
          <ReserveUnitButton
            unit={unit}
            size="sm"
            source="unit_popover"
            title="Reserve this site"
          />
          <WatchButton target={unit} />
        </Button.Group>
      </Card.Section>
    </Card>
  );
}
